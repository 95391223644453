import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import image from "../img/profilbw.png"
import twitter from "../img/twitter.svg"
import linkedin from "../img/linkedin.svg"

const Index = () => {
  const socialLinks = (
    <>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://twitter.com/Olsenlisj"
        className="no-line"
      >
        <img src={twitter} />
      </a>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://www.linkedin.com/in/michael-ishoel-olsen-58784240/"
        className="no-line"
      >
        <img src={linkedin} />
      </a>
    </>
  )
  return (
    <div className="front-page-wrapper">
      <SEO title="" />
      <img className="front-page-pic" src={image} alt="" />
      <div>
        <h1 className="front-page-name">Michael Olsen</h1>
        <div className="front-page-links">
          <div>
            <Link to="/blog">Blog</Link>
          </div>
          <div>
            <Link to="/about">About</Link>
          </div>
        </div>
      </div>
      <div className="front-page-social">{socialLinks}</div>
    </div>
  )
}

Index.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
